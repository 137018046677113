html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* Image preview in company and websites results views. */
.imagePreviewMask {
  background: none;
}

/* Not display some operations in image preview expanded in company and websites results views. */
.ant-image-preview-operations-operation:not(:first-child) {
  display: none;
}

/* Custom fonts. */
@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter/Inter-Medium.ttf");
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter/Inter-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

/* If not below part, button text is not centered vertically. */
.ant-btn {
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/* Login input */
#loginEmailInput.ant-input,
#loginPasswordInput.ant-input,
#signUpEmailInput.ant-input,
#signUpPasswordInput.ant-input,
#signUpConfirmPasswordInput.ant-input,
#forgotPasswordInput.ant-input {
  font-size: 20px;
}

/* Filters */
#companyFiltersCard,
#websiteFiltersCard {
  border-radius: 10px;
}

#companyFiltersCard .ant-card-body {
  padding: 0;
}
#websiteFiltersCard .ant-card-body {
  padding: 0;
}

/* Modal popups */
.ant-modal-content,
.ant-modal-header {
  border-radius: 10px;
}

/* Filter item headers */
.ant-collapse-header {
  padding: 2px 10px !important;
}

/* Snapshot */
.snapshot-container {
  height: 125px;
  width: 200px;
  position: relative;
}

.snapshot-image {
  pointer-events: none;
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
  object-fit: cover;
  transition: 0.3s;
}

.snapshot-image-loading {
  pointer-events: none;
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
  object-fit: cover;
  transition: 0.3s;
  filter: brightness(20%);
}

.snapshot-expand {
  pointer-events: none;
  fill: white;
  transition: 0.5s ease;
  opacity: 0;
  text-align: center;
  height: 40px;
  width: 40px;
  transform: translate(150%, 60%);
}

.snapshot-loader {
  pointer-events: none;
  fill: white;
  transition: 0.5s ease;
  opacity: 1;
  text-align: center;
  height: 75px;
  width: 75px;
  transform: translate(70%, 30%);
}

.box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.stack-top {
  pointer-events: none;
  z-index: 9;
  padding: 10%;
  text-align: center;
}

.snapshot-container:hover .snapshot-image {
  filter: brightness(50%);
}

.snapshot-container:hover .snapshot-expand {
  opacity: 1;
}

/* Detailed snapshot */
.detailed-snapshot-container {
  height: 150px;
  width: 240px;
  position: relative;
}

.detailed-snapshot-image {
  pointer-events: none;
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
  object-fit: cover;
  transition: 0.3s;
}

.detailed-snapshot-image-loading {
  pointer-events: none;
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
  object-fit: cover;
  transition: 0.3s;
  filter: brightness(20%);
}

.detailed-snapshot-expand {
  pointer-events: none;
  fill: white;
  transition: 0.5s ease;
  opacity: 0;
  text-align: center;
  height: 40px;
  width: 40px;
  transform: translate(185%, 75%);
}

.detailed-snapshot-loader {
  pointer-events: none;
  fill: white;
  transition: 0.5s ease;
  opacity: 1;
  text-align: center;
  height: 75px;
  width: 75px;
  transform: translate(95%, 35%);
}

.detailed-snapshot-container:hover .detailed-snapshot-image {
  filter: brightness(50%);
}

.detailed-snapshot-container:hover .detailed-snapshot-expand {
  opacity: 1;
}
